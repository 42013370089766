import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

const Routes = ({match}) =>
	<Switch>
		<Route
			path={ `${match.url}/dashboard` }
			component={ asyncComponent(() => import('./Dashboard')) }
		/>
		<Route
			path={ `${match.url}/fleet` }
			component={ asyncComponent(() => import('./Fleet')) }
		/>
			<Route
			path={ `${match.url}/alerts` }
			component={ asyncComponent(() => import('./Alerts')) }
		/>
		{/* <Route
			exact
			path={ `${match.url}/evidence/:id` }
			component={ asyncComponent(() => import('./Evidences/EvidenceDetail' )) }
		/>

		<Route
			path={ `${match.url}/evidence` }
			component={ asyncComponent(() => import('./Evidences')) }
		/>
		<Route
			path={ `${match.url}/evidence-map` }
			component={ asyncComponent(() => import('./EvidenceMap')) }
		/>
		<Route
			path={ `${match.url}/classify-alert/:id` }
			component={ asyncComponent(() => import('./ClassifyAlerts')) }
		/> */}
		{/*
		<Route
			exact
			path={ `${match.url}/events/:id` }
			component={ asyncComponent(() => import('./Events/EventDetail' )) }
		/>
		<Route
			path={ `${match.url}/events` }
			component={ asyncComponent(() => import('./Events')) }
		/>
		*/}

		<Route component={asyncComponent(() => import("./Page404"))}/>
	</Switch>;


export default withRouter(Routes);
