import WebFont from 'webfontloader';

WebFont.load({
	google: {
		families: [
			'Montserrat:300,400,500,600,700',
			'Source Sans Pro:300,300i,400,600',
		]
	}
});

export default {
	palette: {
		primary: {
			light: '#fdda25',
			main: '#fdda25',
			dark: '#fdda25',
			contrastText: '#464647'
			},
		secondary: {
			light: '#ffffff',
			main: '#ffffff',
			dark: '#ffffff',
			contrastText: '#464647'
		}
	},
	status: {
		danger: 'orange',
	},
	typography: {
		fontFamily: 'Montserrat',
		button: {
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			textTransform: 'uppercase',
		},
		h6: {
			fontFamily: 'Montserrat',
			fontWeight: 700
		},
		subtitle1: {
			fontFamily: 'Montserrat',
			fontWeight: 500,
			fontSize: '0.75rem'
		},
	},
	body: {
		fontFamily: 'Montserrat',
		color: '#fff'
	}
};
