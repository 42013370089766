import React, { useEffect, useState } from 'react';
import { connect, useDispatch} from 'react-redux';
import {
    ThemeProvider,
    createMuiTheme,
} from '@material-ui/core/styles';
import {
    TextField,
    Button,
    CircularProgress
} from '@material-ui/core';
import {
    NotificationContainer,
    NotificationManager
} from 'react-notifications';

import {
    hideMessage,
    showAuthLoader,
    userResetPassword,
} from 'actions/Auth';
import { useHistory } from 'react-router';

const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#fffff",
                "&$focused": {
                    color: "#fdda25",
                }
            },
        },
        MuiInput: {
            input: {
                color: '#fff',
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #fff'
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '2px solid #fff'
                },
                '&:hover:not($disabled):after': {
                    borderBottom: '2px solid #fdda25'
                },
                '&:after': {
                    borderBottom: '2px solid #fdda25'
                }
            }
        }
    }
});


const RecoverPassword = props => {
    const { showMessage, loader, alertMessage, success} = props;
    const [email, setEmail] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();

    const handleOnClick = (email) => {
        let actionCodeSettings = {
            url: 'https://now.gowit.us/signin',
            handleCodeInApp: true
        };
        dispatch(userResetPassword(email, actionCodeSettings))      
    }

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                history.push("/signin");
            }, 900);
        }
    })

    const handleKeyDown = (e) => {
        if(e.keyCode === 13){
            let actionCodeSettings = {
                url: 'https://now.gowit.us/signin',
                handleCodeInApp: true
            };
            dispatch(userResetPassword(e.target.value, actionCodeSettings))     
        }
    }
   
    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
            style={{
                backgroundImage: `url(${require('assets/images/SignIn/background.gif')})`,
                backgroundSize: 'cover'
            }}
        >
            <div className="app-login-main-content">

                <div className="app-login-content">
                    <div className="app-login-header mb-4" style={{ fontWeight: 'bold', fontSize: '3em', textAlign: 'center' }}>
                        Olvidaste tu contraseña?
						</div>

                    <div className="app-login-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    label={"Escribe tu email:"}
                                    fullWidth
                                    onBlur={(e) => setEmail(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                            </ThemeProvider>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <Button onClick={(e) => handleOnClick(email)} variant="contained" color="primary" style={{ margin: 'auto', marginTop: 50 }}
                                >
                                    Recuperar contraseña
								</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                loader &&
                <div className="loader-view">
                    <CircularProgress />
                </div>
            }
            {showMessage && NotificationManager.error(alertMessage)}
            {success && NotificationManager.success("Hemos enviado un link a tu correo")}
            <NotificationContainer />
        </div>
    )
}
const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser, success } = auth;
    return { loader, alertMessage, showMessage, authUser, success }
};

export default connect(mapStateToProps, {
    hideMessage,
    showAuthLoader
})(RecoverPassword);

