import React from 'react';
import {List} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavMenuItem = props => {
	const {name, icon, link} = props;

	return (
		<List component="div" className='nav-menu-item'>
			<NavLink className="prepend-icon nav-menu-link" activeStyle={{
	fontWeight: "bold",
	color:"black"
  }} style={{background:"white", color:"#fdda25"}} to={link}>
				<FontAwesomeIcon icon={ icon } style={{ fontSize: '1.5rem'}} />
				<span className="nav-text px-2" style={{ color:"black" }}>{ name }</span>
			</NavLink>
		</List>
	)
};

export default NavMenuItem;
