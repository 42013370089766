import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {
	ThemeProvider,
	createMuiTheme,
} from '@material-ui/core/styles';
import {
	TextField,
	Button,
	CircularProgress
} from '@material-ui/core';
import {
	NotificationContainer,
	NotificationManager
} from 'react-notifications';

import {
	hideMessage,
	showAuthLoader,
	userSignIn
} from 'actions/Auth';

import './SignIn.css';

const theme = createMuiTheme({
	overrides: {
		MuiFormLabel: {
			root: {
				color: "#fffff",
				"&$focused": {
					color: "#fdda25",
					}
			},
		},
		MuiInput: {
			input: {
				color: '#fff',
			},
			underline: {
				'&:before': {
					borderBottom: '1px solid #fff'
				},
				'&:hover:not($disabled):before': {
					borderBottom: '2px solid #fff'
				},
				'&:hover:not($disabled):after': {
					borderBottom: '2px solid #fdda25'
				},
				'&:after': {
					borderBottom: '2px solid #fdda25'
				}
			}
		}
	}
});

class SignIn extends React.Component {

	constructor() {
		super();
		this.state = {
			email: '',	 //'demo@gowit.us',
			password: '' //'gowitus'
		}
	}

	componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			this.props.history.push('/');
		}
	}
	handleKeyDown = (e) => {
		const { email, password } = this.state;
        if(e.keyCode === 13){
			this.props.showAuthLoader();
			this.props.userSignIn({email,password});  
        }
    }

	render() {
		const { email, password } = this.state;
		const { showMessage, loader, alertMessage } = this.props;

		return (
			<div
				className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
				style={{
					backgroundImage: `url(${ require( 'assets/images/SignIn/background.gif' ) })`,
					backgroundSize: 'cover'
				}}
			>
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<Link className="logo-lg" to="/" title="GoWit">
							<img
								src={ require("assets/images/SignIn/logo.png") }
								alt="gowit"
								title="gowit"
								style={{ width: '100%' }}
							/>
						</Link>
					</div>

					<div className="app-login-content">
						<div className="app-login-header mb-4" style={{ fontWeight: 'bold', fontSize: '4em', textAlign: 'center' }}>
							Iniciar sesión
						</div>

						<div className="app-login-form">
							<form>
								<ThemeProvider theme={theme}>
									<TextField
										label="Usuario:"
										fullWidth
										onChange={ (event) => this.setState({email: event.target.value}) }
										defaultValue={ email }
										margin="normal"
										className="mt-1 my-sm-3"
									/>
									<TextField
										type="password"
										label="Contraseña:"
										fullWidth
										onChange={(event) => this.setState({password: event.target.value})}
										onKeyDown={(e) => this.handleKeyDown(e)}
										defaultValue={ password }
										margin="normal"
										className="mt-1 my-sm-3"
									/>
									<Link to="/recover-password" 
									style={{
											fontFamily: 'Montserrat',
											fontWeight: 300,
											fontSize: '0.8rem',
											margin: 'auto'
										}}>Recuperar tu contraseña</Link>
								</ThemeProvider>
								<div className="mb-3 d-flex align-items-center justify-content-between">
									<Button onClick={() => {
										this.props.showAuthLoader();
										this.props.userSignIn({email, password});
										}} variant="contained" color="primary" style={{ margin: 'auto', marginTop: 50 }}
									>
										CONTINUAR
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect( mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader
} )( SignIn );
