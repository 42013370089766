import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import {
  faExclamationTriangle,
  faTruckMoving, faVideo, faPlayCircle
} from '@fortawesome/free-solid-svg-icons';
import Navigation from "./Navigation";

const SideBarContent = () => {
  const navigationMenus = [
    {
      name: 'MENU',
      type: 'section',
      children: [
        {
          name: 'Flota',
          type: 'item',
          link: '/app/fleet',
          icon: faTruckMoving
        },
		  {
          name: 'Alertas',
          type: 'item',
          link: '/app/alerts',
          icon: faExclamationTriangle
        },
		  // {
      //     name: 'Evidencia',
      //     type: 'item',
      //     link: '/app/evidence',
      //     icon: faVideo
      //   },
		  // {
      //     name: 'Mapa de Evidencias',
      //     type: 'item',
      //     link: '/app/evidence-map',
      //     icon: faMap
      //   },
		  // {
        //   name: 'Videos',
        //   type: 'item',
        //   link: '/app/replay',
        //   icon: faPlayCircle
        // },
		  // {
        //   name: 'Gestión de Flota',
        //   type: 'item',
        //   link: '/app/fms',
        //   icon: faCar
        // },
		  // {
        //   name: 'Documentación',
        //   type: 'item',
        //   link: '/app/documents',
        //   icon: faBook
        // },
		  // {
        //   name: 'Servicios',
        //   type: 'item',
        //   link: '/app/service',
        //   icon: faToolbox
        // },

      ]
    },
  ];

  return (
    <CustomScrollbars style={{background:"white", borderRightStyle: "double", borderWidth: "3px", borderColor: "black"}} className="scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
}

export default SideBarContent;
