import React from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import { toggleCollapsedNav} from "actions/Setting";
import { userSignOut, setUser } from 'actions/Auth';

const firestore = firebase.firestore();

const Index = (props) => {

	const dispatch = useDispatch();
	const { authUser, user } = useSelector( ({auth}) => auth );
	const { drawerType, navCollapsed } = useSelector( ({settings}) => settings );

	if( user === undefined || user === null ){
		firestore.collection( 'users' ).doc( authUser )
			.get()
			.then( doc => {
				dispatch( setUser( doc.data() ));
			})
			.catch( error => {
				console.error(error);
				dispatch( userSignOut() );
			});
	}

	const onToggleCollapsedNav = (e) => {
		const val = !navCollapsed;
		dispatch(toggleCollapsedNav(val));
	};

	const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

	return (
		<AppBar className="app-main-header">
			<Toolbar className="app-toolbar" style={{background: "black"}} disableGutters={false}>

				<IconButton
					className={`jr-menu-icon mr-3 ${drawerStyle}`}
					aria-label="Menu"
					onClick={onToggleCollapsedNav}
				>
					<span className="menu-icon" />
				</IconButton>

				<Link className="app-logo mr-2 d-none d-sm-block" to="/">
					<img alt="" src={ require('assets/images/whitelogo.png') } />
				</Link>

				<IconButton
					aria-label="sign-out"
					onClick={ () => { dispatch( userSignOut() ) }}
					style={{ position: 'absolute', right: '1rem', color: "white" }}
				>
					<FontAwesomeIcon
						icon={ faSignOutAlt }
						size="1x"
					/>
				</IconButton>

		   </Toolbar>
		</AppBar>
	);
};


export default withRouter(Index);
