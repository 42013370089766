import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import SideBarContent from "./SideBarContent";
import Avatar from '@material-ui/core/Avatar';

const SideBar = () => {
    const dispatch = useDispatch();
    const {navCollapsed, drawerType, width, navigationStyle} = useSelector(({settings}) => settings);

    useEffect(() => {
        window.addEventListener('resize', () => {
            dispatch(updateWindowWidth(window.innerWidth))
        });
    }, [dispatch]);

    const onToggleCollapsedNav = (e) => {
        dispatch(toggleCollapsedNav());
    };

    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
        type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
        drawerStyle = '';
        type = 'temporary';
    }

    //console.log("navCollapsed in drawer file", type, navCollapsed)
    return (
        <div className={`app-sidebar d-none ${drawerStyle}`}>
            <Drawer className="app-sidebar-content"
                    variant={type}
                    open={type.includes('temporary') ? navCollapsed : true}
                    onClose={onToggleCollapsedNav}
                    classes={{
                        paper: 'side-nav',
                    }}
            >
				<div className="user-profile d-flex flex-row align-items-center" style={{background: "black"}}>
  <Avatar
	 alt='...'
	//  src={"https://via.placeholder.com/150x150"}
	 className="user-avatar" style={{background: "black", color:"black"}}
  />
</div>

                <SideBarContent/>
            </Drawer>
        </div>
    );
};


export default withRouter(SideBar);
