import firebase from 'firebase'

// Initialize Firebase
const config = {
	apiKey: "AIzaSyBSkoTTGZW6uPce-QKkCUwIljLUKWNE2Uw",
	authDomain: "gowit-331cc.firebaseapp.com",
	databaseURL: "https://gowit-331cc.firebaseio.com",
	projectId: "gowit-331cc",
	storageBucket: "gowit-331cc.appspot.com",
	messagingSenderId: "760666702253",
	appId: "1:760666702253:web:020811b96fde4e0367a124",
	measurementId: "G-KBYVQJZ5NX"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
export {
	auth,
	database,
	googleAuthProvider,
	githubAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider
};
